import { HubProduct, HubApplicationRegistry, IHubApplication } from '@core/types/Application'

import { routes, colors, apiFlags } from './index'

const applications: Record<HubProduct, Partial<IHubApplication>> = {
  [HubProduct.BILLING]: {
    title: 'Billing Manager',
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="75.062" height="60" viewBox="0 0 75.062 60">
        <path id="money-bill-transfer-solid"
          d="M65.084,2.879a2.657,2.657,0,0,1,3.8-.079l.079.079L76.5,10.408a2.4,2.4,0,0,1,.951,1.981,2.363,2.363,0,0,1-.951,1.9L68.967,21.82a2.523,2.523,0,0,1-3.566.317c-.079-.079-.238-.159-.317-.317a2.657,2.657,0,0,1-.079-3.8l.079-.079,2.695-2.774H47.411a2.626,2.626,0,0,1-2.774-2.615v-.079a2.878,2.878,0,0,1,2.774-2.853h0l20.447-.079L65.163,6.921A2.8,2.8,0,0,1,65.084,2.879ZM14.6,46.15l-2.615,2.774,20.288-.079a2.756,2.756,0,0,1,2.774,2.774h0a2.731,2.731,0,0,1-2.536,2.853h-.238l-20.288.079L14.6,57.245a2.523,2.523,0,0,1,.317,3.566c-.079.079-.159.238-.317.317a2.523,2.523,0,0,1-3.566.317c-.079-.079-.238-.158-.317-.317L3.189,53.6A2.369,2.369,0,0,1,2.4,51.618a2.767,2.767,0,0,1,.793-1.9l7.529-7.529a2.657,2.657,0,0,1,3.8-.079l.079.079a2.523,2.523,0,0,1,.317,3.566,1.391,1.391,0,0,0-.317.4ZM13.492,9.536H41.943a5.485,5.485,0,0,0-.713,2.695,6.072,6.072,0,0,0,5.944,6.1H58.744a7.59,7.59,0,0,0,3.328,4.993,3.407,3.407,0,0,0,.713.792,6.123,6.123,0,0,0,8.638,0l2.219-2.3V46.942a7.525,7.525,0,0,1-7.529,7.529H37.742a6.288,6.288,0,0,0-3.091-8.4,6.534,6.534,0,0,0-2.457-.555L21.021,45.6a7.619,7.619,0,0,0-3.329-4.914,3.407,3.407,0,0,0-.713-.792,6.123,6.123,0,0,0-8.638,0l-2.378,2.3v-25.2a7.56,7.56,0,0,1,7.529-7.45Zm0,14.978a7.492,7.492,0,0,0,7.529-7.37v-.159H13.492ZM66.114,47.022V39.493a7.525,7.525,0,0,0-7.529,7.529ZM39.8,43.3A11.254,11.254,0,1,0,28.549,32.043,11.3,11.3,0,0,0,39.8,43.3Z"
          transform="translate(-2.385 -2.037)" />
      </svg>
  },
  [HubProduct.WAREHOUSE_MANAGER_CLASSIC_ADMIN]: {
    title: 'Warehouse Management Classic Admin',
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="75.155" height="60" viewBox="0 0 75.155 60">
        <path id="shelves-solid" d="M72.646,1.5a3.659,3.659,0,0,0-3.73,3.651V20.308H8.76V5.15A3.8,3.8,0,0,0,4.871,1.5,3.677,3.677,0,0,0,1.3,5.15V61.5H8.839v-3.73H68.916V61.5h7.539V5.15A3.778,3.778,0,0,0,72.646,1.5Zm-3.73,48.807H8.76V27.768H68.837V50.307ZM44.473,16.5H55.742a1.931,1.931,0,0,0,1.9-1.9V3.4a1.931,1.931,0,0,0-1.9-1.9H44.473a1.931,1.931,0,0,0-1.9,1.9V14.673a1.864,1.864,0,0,0,1.9,1.825ZM14.395,46.577H25.664a1.931,1.931,0,0,0,1.9-1.9V33.4a1.931,1.931,0,0,0-1.9-1.9H14.395a1.931,1.931,0,0,0-1.9,1.9V44.672A1.987,1.987,0,0,0,14.395,46.577Zm18.809,0H44.473a1.931,1.931,0,0,0,1.9-1.9V33.4a1.931,1.931,0,0,0-1.9-1.9H33.2a1.931,1.931,0,0,0-1.9,1.9V44.672a1.931,1.931,0,0,0,1.9,1.9Z" transform="translate(-1.3 -1.497)" />
      </svg>

  },
  [HubProduct.WAREHOUSE_MANAGER_UNLIMITED_ADMIN]: {
    title: 'Warehouse Management Admin',
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="75.155" height="60" viewBox="0 0 75.155 60">
        <path id="shelves-solid" d="M72.646,1.5a3.659,3.659,0,0,0-3.73,3.651V20.308H8.76V5.15A3.8,3.8,0,0,0,4.871,1.5,3.677,3.677,0,0,0,1.3,5.15V61.5H8.839v-3.73H68.916V61.5h7.539V5.15A3.778,3.778,0,0,0,72.646,1.5Zm-3.73,48.807H8.76V27.768H68.837V50.307ZM44.473,16.5H55.742a1.931,1.931,0,0,0,1.9-1.9V3.4a1.931,1.931,0,0,0-1.9-1.9H44.473a1.931,1.931,0,0,0-1.9,1.9V14.673a1.864,1.864,0,0,0,1.9,1.825ZM14.395,46.577H25.664a1.931,1.931,0,0,0,1.9-1.9V33.4a1.931,1.931,0,0,0-1.9-1.9H14.395a1.931,1.931,0,0,0-1.9,1.9V44.672A1.987,1.987,0,0,0,14.395,46.577Zm18.809,0H44.473a1.931,1.931,0,0,0,1.9-1.9V33.4a1.931,1.931,0,0,0-1.9-1.9H33.2a1.931,1.931,0,0,0-1.9,1.9V44.672a1.931,1.931,0,0,0,1.9,1.9Z" transform="translate(-1.3 -1.497)" />
      </svg>
  },
  [HubProduct.WAREHOUSE_MANAGER_CUSTOMER_PORTAL]: {
    title: 'Warehouse Management Customer Portal',
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="75.155" height="60" viewBox="0 0 75.155 60">
        <path id="shelves-solid" d="M72.646,1.5a3.659,3.659,0,0,0-3.73,3.651V20.308H8.76V5.15A3.8,3.8,0,0,0,4.871,1.5,3.677,3.677,0,0,0,1.3,5.15V61.5H8.839v-3.73H68.916V61.5h7.539V5.15A3.778,3.778,0,0,0,72.646,1.5Zm-3.73,48.807H8.76V27.768H68.837V50.307ZM44.473,16.5H55.742a1.931,1.931,0,0,0,1.9-1.9V3.4a1.931,1.931,0,0,0-1.9-1.9H44.473a1.931,1.931,0,0,0-1.9,1.9V14.673a1.864,1.864,0,0,0,1.9,1.825ZM14.395,46.577H25.664a1.931,1.931,0,0,0,1.9-1.9V33.4a1.931,1.931,0,0,0-1.9-1.9H14.395a1.931,1.931,0,0,0-1.9,1.9V44.672A1.987,1.987,0,0,0,14.395,46.577Zm18.809,0H44.473a1.931,1.931,0,0,0,1.9-1.9V33.4a1.931,1.931,0,0,0-1.9-1.9H33.2a1.931,1.931,0,0,0-1.9,1.9V44.672a1.931,1.931,0,0,0,1.9,1.9Z" transform="translate(-1.3 -1.497)" />
      </svg>
  },
  [HubProduct.CONNECTION_MANAGER]: {
    title: 'Connection Manager',
    logo: (props: any) =>
      <svg { ...props } width="75.155" height="60" viewBox="0 0 75.155 60">
        <path id="shelves-solid" d="M72.646,1.5a3.659,3.659,0,0,0-3.73,3.651V20.308H8.76V5.15A3.8,3.8,0,0,0,4.871,1.5,3.677,3.677,0,0,0,1.3,5.15V61.5H8.839v-3.73H68.916V61.5h7.539V5.15A3.778,3.778,0,0,0,72.646,1.5Zm-3.73,48.807H8.76V27.768H68.837V50.307ZM44.473,16.5H55.742a1.931,1.931,0,0,0,1.9-1.9V3.4a1.931,1.931,0,0,0-1.9-1.9H44.473a1.931,1.931,0,0,0-1.9,1.9V14.673a1.864,1.864,0,0,0,1.9,1.825ZM14.395,46.577H25.664a1.931,1.931,0,0,0,1.9-1.9V33.4a1.931,1.931,0,0,0-1.9-1.9H14.395a1.931,1.931,0,0,0-1.9,1.9V44.672A1.987,1.987,0,0,0,14.395,46.577Zm18.809,0H44.473a1.931,1.931,0,0,0,1.9-1.9V33.4a1.931,1.931,0,0,0-1.9-1.9H33.2a1.931,1.931,0,0,0-1.9,1.9V44.672a1.931,1.931,0,0,0,1.9,1.9Z" transform="translate(-1.3 -1.497)" />
      </svg>
  },
  [HubProduct.WAREHOUSE_MANAGER_MOBILE]: {
    title: 'Warehouse Management Mobile',
    logo: (props: any) =>
      <svg { ...props } width="75.155" height="60" viewBox="0 0 75.155 60">
        <path id="shelves-solid" d="M72.646,1.5a3.659,3.659,0,0,0-3.73,3.651V20.308H8.76V5.15A3.8,3.8,0,0,0,4.871,1.5,3.677,3.677,0,0,0,1.3,5.15V61.5H8.839v-3.73H68.916V61.5h7.539V5.15A3.778,3.778,0,0,0,72.646,1.5Zm-3.73,48.807H8.76V27.768H68.837V50.307ZM44.473,16.5H55.742a1.931,1.931,0,0,0,1.9-1.9V3.4a1.931,1.931,0,0,0-1.9-1.9H44.473a1.931,1.931,0,0,0-1.9,1.9V14.673a1.864,1.864,0,0,0,1.9,1.825ZM14.395,46.577H25.664a1.931,1.931,0,0,0,1.9-1.9V33.4a1.931,1.931,0,0,0-1.9-1.9H14.395a1.931,1.931,0,0,0-1.9,1.9V44.672A1.987,1.987,0,0,0,14.395,46.577Zm18.809,0H44.473a1.931,1.931,0,0,0,1.9-1.9V33.4a1.931,1.931,0,0,0-1.9-1.9H33.2a1.931,1.931,0,0,0-1.9,1.9V44.672a1.931,1.931,0,0,0,1.9,1.9Z" transform="translate(-1.3 -1.497)" />
      </svg>
  },
  [HubProduct.WAREHOUSE_MANAGER_ACCOUNT_MANAGER]: {
    title: 'Warehouse Management Account Manager',
    logo: (props: any) =>
      <svg { ...props } width="75.155" height="60" viewBox="0 0 75.155 60">
        <path id="shelves-solid" d="M72.646,1.5a3.659,3.659,0,0,0-3.73,3.651V20.308H8.76V5.15A3.8,3.8,0,0,0,4.871,1.5,3.677,3.677,0,0,0,1.3,5.15V61.5H8.839v-3.73H68.916V61.5h7.539V5.15A3.778,3.778,0,0,0,72.646,1.5Zm-3.73,48.807H8.76V27.768H68.837V50.307ZM44.473,16.5H55.742a1.931,1.931,0,0,0,1.9-1.9V3.4a1.931,1.931,0,0,0-1.9-1.9H44.473a1.931,1.931,0,0,0-1.9,1.9V14.673a1.864,1.864,0,0,0,1.9,1.825ZM14.395,46.577H25.664a1.931,1.931,0,0,0,1.9-1.9V33.4a1.931,1.931,0,0,0-1.9-1.9H14.395a1.931,1.931,0,0,0-1.9,1.9V44.672A1.987,1.987,0,0,0,14.395,46.577Zm18.809,0H44.473a1.931,1.931,0,0,0,1.9-1.9V33.4a1.931,1.931,0,0,0-1.9-1.9H33.2a1.931,1.931,0,0,0-1.9,1.9V44.672a1.931,1.931,0,0,0,1.9,1.9Z" transform="translate(-1.3 -1.497)" />
      </svg>
  },
  [HubProduct.INTEGRATION_MANAGER]: {
    title: 'Integration Management',
    supportsMultiInstance: true,
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="74.737" height="60" viewBox="0 0 74.737 60">
        <path id="Cart_Rover" data-name="Cart Rover" d="M7.5,2.812A2.813,2.813,0,0,1,10.312,0H18.75a2.822,2.822,0,0,1,2.766,2.286l.176,1.464h49.3A3.8,3.8,0,0,1,74.6,8.515l-6.328,22.5a3.751,3.751,0,0,1-3.609,2.73H27.5l1.078,5.625H64.687a2.813,2.813,0,0,1,0,5.625H26.144a2.8,2.8,0,0,1-2.66-2.285L16.418,5.625H10.312A2.813,2.813,0,0,1,7.5,2.812ZM33.75,54.375a5.625,5.625,0,1,1-5.625-5.625A5.626,5.626,0,0,1,33.75,54.375Zm22.5,0A5.625,5.625,0,1,1,61.875,60,5.626,5.626,0,0,1,56.25,54.375ZM12.187,11.25a2.812,2.812,0,0,1,0,5.625H2.812a2.812,2.812,0,0,1,0-5.625Zm1.875,9.375a2.813,2.813,0,0,1,0,5.625H2.812a2.813,2.813,0,0,1,0-5.625ZM15.937,30a2.813,2.813,0,0,1,0,5.625H2.812a2.813,2.813,0,0,1,0-5.625Z" />
      </svg>
  },
  [HubProduct.NETWORK_MANAGER]: {
    title: 'Network Manager',
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="74.047" height="60" viewBox="0 0 74.047 60">
        <path id="Union_1" d="M60.792,57.58a7.939,7.939,0,0,1-1.074-4.758L52.2,47.986l-.691.691a15.779,15.779,0,0,1-5.142,3.53,16.068,16.068,0,0,1-15.426-1.535,16.459,16.459,0,0,1-5.91-7.214,14.82,14.82,0,0,1-.921-3.147l-8.519.153A7.611,7.611,0,0,1,5.3,44,7.74,7.74,0,0,1,12.134,30.1a8.145,8.145,0,0,1,3.454,3.377l8.673-.153a15.732,15.732,0,0,1,2.379-5.449A15.52,15.52,0,0,1,30.4,23.887L27.023,16.98A7.791,7.791,0,1,1,34.7,9.228a7.554,7.554,0,0,1-1.535,4.6l3.53,7.138a16.053,16.053,0,0,1,14.122,3.761l8.366-6.063a7.732,7.732,0,0,1,6.447-8.826,7.6,7.6,0,0,1,8.749,6.524,7.732,7.732,0,0,1-6.447,8.826,7.861,7.861,0,0,1-4.758-.767l-8.289,5.986c.077.077.077.23.153.307a17.02,17.02,0,0,1,.614,11.205l7.828,5.065a7.591,7.591,0,0,1,10.514,2.686,7.837,7.837,0,0,1-2.686,10.668A7.68,7.68,0,0,1,60.792,57.58Zm-23.1-26.4a6.6,6.6,0,0,0-3.454,8.6,6.487,6.487,0,0,0,8.519,3.53,6.6,6.6,0,0,0,3.454-8.6,6.425,6.425,0,0,0-5.986-3.991A7.054,7.054,0,0,0,37.691,31.178Z" transform="translate(-1.024 -1.4)" />
      </svg>
  },
  [HubProduct.THREEPL_WAREHOUSE_MANAGER]: {
    title: '3PL Warehouse Manager',
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="75.104" height="60" viewBox="0 0 75.104 60">
        <path id="warehouse-full-solid" d="M1.8,58.288V21.053a7.583,7.583,0,0,1,4.763-6.986L38,1.523a3.391,3.391,0,0,1,2.779,0L72.22,14.146A7.431,7.431,0,0,1,76.9,21.132V58.367a2.883,2.883,0,0,1-2.779,2.858H68.489a2.883,2.883,0,0,1-2.858-2.779h0V27.166A3.727,3.727,0,0,0,61.9,23.435H16.8a3.727,3.727,0,0,0-3.731,3.731V58.288a2.883,2.883,0,0,1-2.779,2.858H4.579A2.939,2.939,0,0,1,1.8,58.288ZM59.121,46.061a2.883,2.883,0,0,1,2.858,2.779h0v9.368A2.883,2.883,0,0,1,59.2,61.066H45.942a2.761,2.761,0,0,1-2.779-2.779h0V48.84a2.761,2.761,0,0,1,2.779-2.779H59.121ZM16.8,29.945a2.813,2.813,0,0,1,2.779-2.779h16.91a2.813,2.813,0,0,1,2.779,2.779v9.527a2.761,2.761,0,0,1-2.779,2.779H19.663a2.761,2.761,0,0,1-2.779-2.779h0V29.945ZM36.573,46.061a2.761,2.761,0,0,1,2.779,2.779h0v9.368a2.883,2.883,0,0,1-2.779,2.858H19.663a2.761,2.761,0,0,1-2.779-2.779h0V48.84a2.761,2.761,0,0,1,2.779-2.779h16.91Z" transform="translate(-1.8 -1.225)" />
      </svg>
  },
  [HubProduct.SMARTSCAN]: {
    title: 'SmartScan',
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="67.5" height="60" viewBox="0 0 67.5 60">
        <path id="barcode-read-solid" d="M6.563,5.625a.94.94,0,0,0-.937.938v9.375a2.813,2.813,0,0,1-5.625,0V6.563A6.567,6.567,0,0,1,6.563,0h9.375a2.813,2.813,0,0,1,0,5.625ZM17.813,15h1.875A2.806,2.806,0,0,1,22.5,17.813V42.188A2.806,2.806,0,0,1,19.688,45H17.813A2.806,2.806,0,0,1,15,42.188V17.813A2.806,2.806,0,0,1,17.813,15Zm18.75,0h1.875a2.806,2.806,0,0,1,2.813,2.813V42.188A2.806,2.806,0,0,1,38.438,45H36.563a2.806,2.806,0,0,1-2.812-2.812V17.813A2.806,2.806,0,0,1,36.563,15ZM45,17.813A2.806,2.806,0,0,1,47.813,15h1.875A2.806,2.806,0,0,1,52.5,17.813V42.188A2.806,2.806,0,0,1,49.688,45H47.813A2.806,2.806,0,0,1,45,42.188ZM28.125,15A1.881,1.881,0,0,1,30,16.875v26.25a1.875,1.875,0,0,1-3.75,0V16.875A1.881,1.881,0,0,1,28.125,15Zm33.75-8.437a.94.94,0,0,0-.937-.937H51.563a2.813,2.813,0,0,1,0-5.625h9.375A6.567,6.567,0,0,1,67.5,6.563v9.375a2.813,2.813,0,0,1-5.625,0ZM6.563,54.375h9.375a2.813,2.813,0,0,1,0,5.625H6.563A6.567,6.567,0,0,1,0,53.438V44.063a2.813,2.813,0,0,1,5.625,0v9.375A.94.94,0,0,0,6.563,54.375Zm55.313-.937V44.063a2.813,2.813,0,0,1,5.625,0v9.375A6.567,6.567,0,0,1,60.938,60H51.563a2.813,2.813,0,0,1,0-5.625h9.375A.94.94,0,0,0,61.875,53.438Z" />
      </svg>

  },
  [HubProduct.MANAGEMENT_CONSOLE]: {
    title: 'Management Console',
    logo: (props: any) =>
      <svg { ...props } xmlns='http://www.w3.org/2000/svg' width='128.267' height='150.769' viewBox='0 0 128.267 150.769' >
        <g id='Group_1620' data-name='Group 1620' transform='translate(-816.459 -547.632)'>
          <path id='Path_1386' data-name='Path 1386' d='M324.156,218.771V319.833l-33.173,49.7,33.173-35.151v0l57.808-57.8Z' transform='translate(550.615 328.862)' fill='#701a4a' />
          <path id='Path_1390' data-name='Path 1390' d='M490.241,262.9,475.035,278.1l27.35,27.346V262.9Z' transform='translate(430.193 299.99)' fill='#eaa4d1' />
          <path id='Path_1392' data-name='Path 1392' d='M276.509,560.806l33.174-49.7-40.82,18.807Z' transform='translate(565.088 137.595)' fill='#eaa4d1' />
          <path id='Path_1393' data-name='Path 1393' d='M276.579,277.075V218.769H218.268Z' transform='translate(598.191 328.863)' fill='#eaa4d1' />
          <path id='Path_1394' data-name='Path 1394' d='M519.011,239.917H543.3l-12.148-12.145Z' transform='translate(401.421 322.972)' fill='#701a4a' />
        </g>
      </svg>
  },
  [HubProduct.SIGNUP]: {
    title: 'Signup',
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="75" height="60" viewBox="0 0 75 60">
        <path id="Sign_Up_App" data-name="Sign Up App" d="M26.25,30a15,15,0,1,0-15-15A15,15,0,0,0,26.25,30Zm5.941,5.625H20.309A20.311,20.311,0,0,0,0,55.934,4.062,4.062,0,0,0,4.062,60H48.441A4.06,4.06,0,0,0,52.5,55.934,20.31,20.31,0,0,0,32.191,35.625Zm40-12.187H66.563V17.813a2.813,2.813,0,0,0-5.625,0v5.625H55.313a2.813,2.813,0,0,0,0,5.625h5.625v5.625a2.813,2.813,0,0,0,5.625,0V29.063h5.625a2.813,2.813,0,0,0,0-5.625Z" />
      </svg>
  },
  [HubProduct.ORDER_MANAGER]: {
    title: 'Order Management',
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="75" height="60" viewBox="0 0 75 60">
        <path id="Skubana" d="M52.5,18.75H60A3.761,3.761,0,0,0,63.75,15V7.5A3.761,3.761,0,0,0,60,3.75H52.5A3.761,3.761,0,0,0,48.75,7.5V15A3.761,3.761,0,0,0,52.5,18.75Zm0,18.75h15a3.761,3.761,0,0,0,3.75-3.75v-7.5A3.761,3.761,0,0,0,67.5,22.5h-15a3.761,3.761,0,0,0-3.75,3.75v7.5A3.761,3.761,0,0,0,52.5,37.5Zm-26.25,0h15A3.761,3.761,0,0,0,45,33.75V7.5a3.761,3.761,0,0,0-3.75-3.75h-15A3.761,3.761,0,0,0,22.5,7.5V33.75A3.761,3.761,0,0,0,26.25,37.5ZM75,48.645a3.607,3.607,0,0,1-3.75,3.645H63.412a5.627,5.627,0,1,1-10.566,0H29.66A8.029,8.029,0,0,1,30,54.375,5.625,5.625,0,1,1,19.092,52.5H11.25A3.785,3.785,0,0,1,7.5,48.645V9.375A1.874,1.874,0,0,0,5.625,7.5H3.645a3.752,3.752,0,0,1,0-7.5H7.5A7.522,7.522,0,0,1,15,7.5V45H71.25A3.741,3.741,0,0,1,75,48.645Z" />
      </svg>
  },
  [HubProduct.HUB]: {
    title: 'Extensiv',
    logo: (props: any) =>
      <svg { ...props } viewBox="0 0 24 24">
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </svg>
  },
  [HubProduct.ADMIN]: {
    title: 'Admin',
    logo: (props: any) =>
      <svg { ...props } id="perm_data_setting_black_24dp" xmlns="http://www.w3.org/2000/svg" width="49.02" height="49.02" viewBox="0 0 49.02 49.02">
        <path id="Path_3" data-name="Path 3" d="M0,0H49.02V49.02H0Z" fill="none" />
        <path id="Path_4" data-name="Path 4" d="M36.744,23.631H40.85V0L0,40.85H23.611V36.765H9.865l26.879-26.9ZM48.55,41.5l-2.185-1.7a7.938,7.938,0,0,0,.082-1,5.369,5.369,0,0,0-.082-1l2.165-1.7a.53.53,0,0,0,.123-.654l-2.042-3.533a.518.518,0,0,0-.633-.225l-2.533,1.021a6.987,6.987,0,0,0-1.736-1l-.388-2.7a.488.488,0,0,0-.49-.429H36.744a.524.524,0,0,0-.511.429l-.388,2.7a8.16,8.16,0,0,0-1.736,1L31.577,31.7a.535.535,0,0,0-.633.225L28.9,35.457a.506.506,0,0,0,.123.654l2.165,1.7a8.2,8.2,0,0,0,0,2l-2.165,1.7a.53.53,0,0,0-.123.654l2.042,3.533a.518.518,0,0,0,.633.225l2.533-1.021a6.987,6.987,0,0,0,1.736,1l.388,2.7a.508.508,0,0,0,.511.429h4.085a.524.524,0,0,0,.511-.429l.388-2.7a7.549,7.549,0,0,0,1.716-1L46,45.915a.535.535,0,0,0,.633-.225l2.042-3.533A.53.53,0,0,0,48.55,41.5Zm-9.763.368a3.064,3.064,0,1,1,3.064-3.064A3.06,3.06,0,0,1,38.787,41.871Z" />
      </svg>
  },
  [HubProduct.FULFILLMENT_MARKETPLACE]: {
    title: 'Fulfillment Marketplace',
    isNew: true,
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="75.062" height="60" viewBox="0 0 640 512">
        <path id="shop" d="M36.8,192H603.2a36.768,36.768,0,0,0,30.6-57.2L558.2,21.4A47.773,47.773,0,0,0,518.3,0H121.7A47.9,47.9,0,0,0,81.8,21.4L6.2,134.7A37.317,37.317,0,0,0,0,155.1,36.852,36.852,0,0,0,36.8,192ZM64,224V464a48.012,48.012,0,0,0,48,48H336a48.012,48.012,0,0,0,48-48V224H320V384H128V224Zm448,0V480a32,32,0,0,0,64,0V224Z" transform="translate(-2.385 -2.037)" />
      </svg>
  },
  [HubProduct.ACCOUNTING_INTEGRATION]: {
    title: 'Accounting Integration',
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="75" height="60" viewBox="0 0 75 60">
        <path id="display-chart-up-circle-dollar-regular" d="M7.5,5.625H60A1.881,1.881,0,0,1,61.875,7.5V22.84A20.442,20.442,0,0,1,67.5,24.75V7.5A7.507,7.507,0,0,0,60,0H7.5A7.507,7.507,0,0,0,0,7.5V41.25a7.507,7.507,0,0,0,7.5,7.5H24.809l-.937,5.625H17.813a2.813,2.813,0,0,0,0,5.625H46.266a20.659,20.659,0,0,1-5.426-5.625H29.566L30.5,48.75h7.781a20.763,20.763,0,0,1-.773-5.625H7.5A1.881,1.881,0,0,1,5.625,41.25V7.5A1.881,1.881,0,0,1,7.5,5.625ZM56.25,22.582v-8.52a2.806,2.806,0,0,0-2.812-2.812H44.063a2.813,2.813,0,0,0,0,5.625h2.59L35.625,27.9,28.242,20.52a2.8,2.8,0,0,0-3.973,0L12.07,32.7a2.809,2.809,0,0,0,3.973,3.973l10.2-10.2,7.383,7.383a2.829,2.829,0,0,0,3.984,0L50.613,20.848v2.59a3,3,0,0,0,.035.457,20.6,20.6,0,0,1,5.59-1.312ZM75,43.125A16.875,16.875,0,1,0,58.125,60,16.875,16.875,0,0,0,75,43.125ZM55.406,39.3a.67.67,0,0,0-.094.422v.012c0,.047,0,.223.562.527a15.755,15.755,0,0,0,2.8.961H58.7a15.546,15.546,0,0,1,3.574,1.348,4.47,4.47,0,0,1,2.4,3.785,4.636,4.636,0,0,1-2.543,4.324,7.254,7.254,0,0,1-2.145.727v1.266a1.875,1.875,0,0,1-3.75,0V51.34a23.453,23.453,0,0,1-2.777-.773h0c-.2-.07-.4-.129-.586-.187A1.872,1.872,0,1,1,53.988,46.8c.234.07.457.141.68.211a13.143,13.143,0,0,0,3.5.8,4.628,4.628,0,0,0,2.168-.422A1.3,1.3,0,0,0,60.809,47a.864.864,0,0,0,.117-.527c0-.164-.023-.352-.551-.656a12.455,12.455,0,0,0-2.719-.973l-.211-.059a17.2,17.2,0,0,1-3.352-1.207,4.373,4.373,0,0,1-2.531-3.6,4.508,4.508,0,0,1,2.555-4.359,7.547,7.547,0,0,1,2.109-.738V33.75a1.875,1.875,0,0,1,3.75,0v1.1a20.674,20.674,0,0,1,2.1.434,1.877,1.877,0,1,1-.9,3.645,13.927,13.927,0,0,0-3.094-.48,4.876,4.876,0,0,0-2.191.469,1.335,1.335,0,0,0-.492.4Z" />
      </svg>

  },
  [HubProduct.ANALYTICS]: {
    title: 'Analytics',
    logo: (props: any) =>
      <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="120" height="105.001" viewBox="0 0 120 105.001">
        <path id="chart-mixed" d="M117.193,45.363a7.5,7.5,0,0,0-9.375-11.719L74.864,60.012,49.5,41a7.494,7.494,0,0,0-9.188.141l-37.5,30a7.5,7.5,0,1,0,9.375,11.719L45.145,56.5,70.5,75.5a7.494,7.494,0,0,0,9.188-.141ZM37.5,84.5v45a7.5,7.5,0,0,0,15,0v-45a7.5,7.5,0,0,0-15,0ZM7.5,107v22.5a7.5,7.5,0,0,0,15,0V107a7.5,7.5,0,1,0-15,0ZM75,92a7.492,7.492,0,0,0-7.5,7.5v30a7.5,7.5,0,0,0,15,0v-30A7.492,7.492,0,0,0,75,92Zm22.5-7.5v45a7.5,7.5,0,0,0,15,0v-45a7.5,7.5,0,0,0-15,0Z" transform="translate(-0.005 -32.005)" />
      </svg>
  }
}

const applicationList: HubApplicationRegistry = Object.entries(applications).reduce((acc, [ key, app ]) => ({
  ...acc,
  [key]: {
    ...app,
    key,
    rootUrl: routes[key as HubProduct],
    apiFlag: apiFlags[key as HubProduct],
    themeColor: colors[key as HubProduct]
  }
}), {} as HubApplicationRegistry)

export default applicationList
