// Anything exported from this file is importable by other in-browser modules.
import { IExtendedUser, IProductAccess, IUserOrgs } from '@extensiv/sdk/hub'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { intersection } from 'lodash'
import { BehaviorSubject, Subject } from 'rxjs'

import applicationList from '@core/constants/applicationList'
import customEvents from '@core/constants/customEvents'
import { verifyUserAuth, verifyMfaCode, getTotpSecretCode } from '@core/services/auth.service'
import { INotificationMessage, NotificationType } from '@core/types/Notification'
import { IWebsocketMessage } from '@core/types/Websockets'

import { Api } from './util/Api'

const environments = process.env

const isGlobalLogout = new BehaviorSubject(false)
const isAnyFormEdited = new BehaviorSubject(false)
const fetchUserInfo = new BehaviorSubject<string | null>(null)
const globalAuthData = new BehaviorSubject<CognitoUserSession | null>(null)
const globalAuthUser = new BehaviorSubject<IExtendedUser | null>(null)
const globalSelectedOrg = new BehaviorSubject<IUserOrgs | null>(null)
const globalSelectedAppName = new BehaviorSubject('')
const globalActiveProducts = new BehaviorSubject<IProductAccess>({})
const websocketMessages = new Subject<IWebsocketMessage>()

export const showNotification = (message: INotificationMessage) => {
  window.dispatchEvent(new CustomEvent(customEvents.NOTIFICATION_MESSAGE, { detail: message }))
}

export const onErrorResponse = (err: any, isShowNotification = true) => {
  const error = err?.response?.data?.error
  if (isShowNotification) {
    showNotification({
      type: NotificationType.ERROR,
      msg: error?.message || err?.message || err
    })
  }
}

const isSuperUser = () => {
  const authUser = globalAuthUser.getValue()
  return authUser?.isSuperUser as any === 'true' || authUser?.isSuperUser === true
}
const isEssentialOrg = () => {
  const authData = globalAuthData.getValue()

  return !!authData?.getIdToken().payload?.isEssential
}

const isOrgOwner = () => {
  const authData = globalAuthData.getValue()

  return authData?.getIdToken().payload?.isowneruser === 'true' || authData?.getIdToken().payload?.isowneruser === true
}

const hasPermission = (
  requiredPermission: string[],
  allowedOwner = true
): boolean => {
  if (requiredPermission?.length === 0 || (isOrgOwner() && allowedOwner)) return true

  const usersPermission = globalAuthData.getValue()?.getIdToken().payload?.perms ? JSON.parse(globalAuthData.getValue()?.getIdToken().payload.perms) : []
  const permissionAccess = intersection(requiredPermission, usersPermission)

  return permissionAccess.length > 0
}



const api = Api.create(
  {
    auth: environments.REACT_APP_API_URL_USER || '',
    org: environments.REACT_APP_API_URL_ORG || '',
    settings: environments.REACT_APP_API_URL_SETTINGS || ''
  },
  environments.REACT_APP_USER_POOL_ID || '',
  environments.REACT_APP_CLIENT_ID || ''
)

export type { IWebsocketMessage } from '@core/types/Websockets'
export type { HubApplicationRegistry, IHubApplication } from '@core/types/Application'
export { HubProduct } from '@core/types/Application'

export {
  environments,
  isGlobalLogout,
  isAnyFormEdited,
  hasPermission,
  globalAuthData,
  globalAuthUser,
  globalSelectedAppName,
  globalSelectedOrg,
  isEssentialOrg,
  isSuperUser,
  globalActiveProducts,
  fetchUserInfo,
  api,
  websocketMessages,
  applicationList,
  NotificationType,
  verifyUserAuth,
  verifyMfaCode,
  getTotpSecretCode
}
