import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js'

export interface NetworkResponse<T = any> {
  error: { message: string }
  status: number
  data?: T
}

export interface RequestInfo {
  method(url: string, body?: any, options?: RequestInit): any
  url: string
  body?: any
  response: any
  options: any
}

export interface ApiRoute {
  get<T = any>(url: string, options?: RequestInit): Promise<NetworkResponse<T>>
  post<T = any>(url: string, body?: any, options?: RequestInit): Promise<NetworkResponse<T>>
  put<T = any>(url: string, body?: any, options?: RequestInit): Promise<NetworkResponse<T>>
  delete<T = any>(url: string, options?: RequestInit): Promise<NetworkResponse<T>>
}

export class Api {
  public app: Record<string, Record<string, ApiRoute>> = {}

  private constructor(
    apiRouteMap: Record<string, string>,
    private userPoolId: string,
    private clientId: string,
    public onUserSessionUpdate: (session: CognitoUserSession) => any
  ) {
    this.onUserSessionUpdate = onUserSessionUpdate
    this.userPoolId = userPoolId
    this.clientId = clientId

    this.bindRestActions(apiRouteMap)
  }

  static create<T extends string>(
    routes: Record<T, string>,
    userPoolId: string,
    clientId: string,
    onUserSessionUpdate: (token: CognitoUserSession) => any = (session: CognitoUserSession) => {}
  ): Api & Record<T, ApiRoute> {
    return new Api(routes, userPoolId, clientId, onUserSessionUpdate) as any
  }

  bindRestActions = (apiRouteMap: Record<string, string>, appKey?: string) => {
    if (appKey && !(this as any).app[appKey]) {
      (this as any).app[appKey] = {}
    }

    const bindLocation = appKey ? (this as any).app[appKey] : (this as any)

    Object.entries(apiRouteMap).forEach(([ key, apiUrl ]) => {
      bindLocation[key] = {
        get: async (url: string, options: RequestInit = {}) => {
          const { status, data: response } = await this.get(apiUrl + url, options)
          const { data, error } = response

          const requestInfo = {
            method: this.get,
            url: apiUrl + url,
            options,
            response
          }

          return await this.validateAuthorization({ data, status, error }, requestInfo)
        },
        post: async (url: string, body: any = {}, options: RequestInit = {}) => {
          const { status, data: response } = await this.post(apiUrl + url, body, options)
          const { data, error } = response

          const requestInfo = {
            method: this.post,
            url: apiUrl + url,
            body,
            options,
            response
          }

          return await this.validateAuthorization({ data, status, error }, requestInfo)
        },
        put: async (url: string, body: any = {}, options: RequestInit = {}) => {
          const { status, data: response } = await this.put(apiUrl + url, body, options)
          const { data, error } = response

          const requestInfo = {
            method: this.put,
            url: apiUrl + url,
            body,
            options,
            response
          }

          return await this.validateAuthorization({ data, status, error }, requestInfo)
        },
        delete: async (url: string, options: RequestInit = {}) => {
          const { status, data: responseData } = await this.delete(apiUrl + url, options)
          let error

          if (status == 500) {
            error = 'Internal Server Error'
          } else if (!status.toString().startsWith('20')) {
            error = responseData.error
          }

          const requestInfo = {
            method: this.delete,
            url: apiUrl + url,
            options,
            response: responseData
          }

          return await this.validateAuthorization({ status, error }, requestInfo)
        }
      }
    })
  }

  authToken: string | null = null

  augmentWithToken = (opt: RequestInit) => {
    let options = opt

    if (this.authToken) {
      options = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${this.authToken}`
        }
      }
    }

    if ([ 'POST', 'PUT' ].includes(opt.method!)) {
      options = {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers,
        },
      }
    }

    return options
  }

  registerAppApi<T extends string>(
    appKey: string,
    apiRouteMap: Record<T, string>
  ): Record<T, ApiRoute> {
    this.bindRestActions(apiRouteMap, appKey)
    return this.app[appKey]
  }

  parseJsonReponse = async (response: any) => {
    try {
      return await response.json()
    } catch (_) {
      return null
    }
  }

  get = async (url: string, options: RequestInit = {}) => {
    const response = await fetch(url, this.augmentWithToken(options))
    const responseJson = await this.parseJsonReponse(response)

    return { status: response.status, data: responseJson }
  }

  post = async (url: string, body: any = {}, options: RequestInit = {}) => {
    const response = await fetch(
      url,
      {
        ...this.augmentWithToken({ method: 'POST', ...options }),
        body: JSON.stringify(body)
      }
    )
    const responseJson = await this.parseJsonReponse(response)

    return { status: response.status, data: responseJson }
  }

  put = async (url: string, body: any = {}, options: RequestInit = {}) => {
    const response = await fetch(
      url,
      {
        ...this.augmentWithToken({ method: 'PUT', ...options }),
        body: JSON.stringify(body)
      }
    )

    const responseJson = await this.parseJsonReponse(response)

    return { status: response.status, data: responseJson }
  }

  delete = async (url: string, options: RequestInit = {}) => {
    const response = await fetch(
      url,
      this.augmentWithToken({ method: 'DELETE', ...options })
    )

    const responseJson = await this.parseJsonReponse(response)

    return { status: response.status, data: responseJson }
  }

  // If the request fails with a 401, get a new cognito token and retry the request.
  // If it fails again, we don't have a valid session. Logs the user out and sends them
  // back to the login page
  validateAuthorization = async ({ error, status, data }: NetworkResponse, requestInfo: RequestInfo) => {
    const returnValue = { error, data, status }

    if (status == 401) {
      const newCognitoSession = await this.refreshCognitoUserSession() as CognitoUserSession

      await this.onUserSessionUpdate(newCognitoSession)

      // Retry the request
      let apiCall
      if (requestInfo.body) {
        apiCall = requestInfo.method(requestInfo.url, requestInfo.body, requestInfo.options)
      } else {
        apiCall = requestInfo.method(requestInfo.url, requestInfo.options)
      }

      const { status: retryStatus, data: retryResponse } = await apiCall

      if (retryStatus.toString().startsWith('20')) {
        returnValue.status = retryStatus
        returnValue.data = retryResponse.data
      } else {
        const cognitoUser = new CognitoUserPool({
          UserPoolId: this.userPoolId!,
          ClientId: this.clientId!
        }).getCurrentUser()

        if (cognitoUser) {
          cognitoUser.signOut()
        }

        (window as Window).location = '/'
      }
    }

    return returnValue
  }

  refreshCognitoUserSession = async () => new Promise((res, rej) => {
    const cognitoUser = new CognitoUserPool({
      UserPoolId: this.userPoolId!,
      ClientId: this.clientId!
    }).getCurrentUser()

    if (!cognitoUser) return rej('No user found')

    cognitoUser.getSession(async (err: any, session: CognitoUserSession) => {
      cognitoUser.refreshSession(session.getRefreshToken(), (err: any, s: CognitoUserSession) => {
        if (err) rej(err)
        res(s)
      })
    })
  })
}
